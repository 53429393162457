<button class="sorter" *ngIf="isSortable && table; else content" type="button">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
  {{ table.change$ | async }}
  <span class="sort__block" [class.sort__block_active]="isCurrent" (click)="updateSorter($event)">
    <span class="sorter__number" [style.visibility]="count > 1 && num ? 'visible' : 'hidden'">{{
      num ?? '0'
    }}</span>
    <prizm-icon class="sorter__icon" [iconClass]="$any(icon$ | async)"></prizm-icon>
  </span>
</button>
<ng-template #content>
  <div class="cell"><ng-content></ng-content></div>
</ng-template>
<div class="resize-bar" *ngIf="resizable" (prizmResized)="onResized($event)"></div>
