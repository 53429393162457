<prizm-dropdown-host
  #dropdownHostRef
  *prizmLet="{ selectedItems: selectedItems$ | async, filteredItems: filteredItems$ | async } as $"
  [(isOpen)]="open"
  [content]="dropdown"
  [prizmDropdownHostWidth]="dropdownWidth"
  [prizmDropdownMinHeight]="minDropdownHeight"
  [prizmDropdownMaxHeight]="maxDropdownHeight"
>
  <prizm-input-layout
    [label]="label"
    [forceClear]="(forceClear || forceClear === null) && $.selectedItems.length > 0"
    [outer]="outer"
    [size]="size"
    [ngSwitch]="outer"
    (clear)="focusableElementRef.focus(); safeStopPropagation(focusableElementRef.value, $event)"
    (click)="safeOpenModal()"
  >
    <input
      #focusableElementRef
      #inputText="prizmInput"
      [style.display]="outer ? 'none' : ''"
      [style.visibility]="outer ? 'none' : 'hidden'"
      [hidden]="true"
      [disabled]="disabled"
      [readonly]="true"
      [placeholder]="placeholder"
      [formControl]="requiredInputControl"
      (prizmOnInit)="inputTextElement = inputText"
      (onClear)="onClear()"
      prizmInput
    />
    <div class="in-body-chips-box" *ngSwitchCase="true" prizm-input-in-body>
      <ng-container [ngTemplateOutlet]="chipsTemplate"></ng-container>
    </div>

    <ng-container *ngSwitchCase="false" prizm-input-bottom>
      <ng-container [ngTemplateOutlet]="chipsTemplate"></ng-container>
    </ng-container>

    <ng-template #chipsTemplate>
      <ng-container *ngIf="selectedItemsChips$ | async as chips">
        <prizm-chips
          #chipsComponent
          *ngIf="chips.length"
          [class.inner]="!outer"
          [singleLine]="true"
          [deletable]="!disabled && isChipsDeletable"
          [chips]="chips"
          (removeChipEvent)="removeChip($event)"
        ></prizm-chips>
      </ng-container>
    </ng-template>

    <ng-container prizm-input-right>
      <ng-container
        *polymorphOutlet="icon || defaultIcon as iconName; context: { opened: open, disabled: disabled }"
      >
        <prizm-icon
          *ngIf="!disabled"
          [class.opened]="open"
          [class.active]="focusableElementRef.focused"
          [class.icon-dropdown]="iconName === defaultIcon"
          [iconClass]="$any(iconName)"
          (click)="focusableElementRef.focus()"
        >
        </prizm-icon>
      </ng-container>
    </ng-container>

    <!--    <prizm-icon-->
    <!--      *ngIf="!disabled"-->
    <!--      (click)="focusableElementRef.focus()"-->
    <!--      [class.active]="focusableElementRef.focused"-->
    <!--      [class.opened]="open"-->
    <!--      class="icon-dropdown"-->
    <!--      prizm-input-right-->
    <!--      iconClass="chevrons-dropdown"-->
    <!--    ></prizm-icon>-->
  </prizm-input-layout>

  <ng-template #dropdown>
    <prizm-data-list class="block" [scroll]="dropdownScroll" [style.--prizm-data-list-border]="0">
      <div class="list-search-item" *ngIf="searchable">
        <prizm-input-layout size="m" label="Поиск">
          <input class="input-search" #input [formControl]="searchInputControl" prizmInput prizmAutoFocus />
          <button [interactive]="true" prizmInputIconButton="sort-zoom-in" prizm-input-right></button>
        </prizm-input-layout>
      </div>
      <ng-container *ngIf="$.filteredItems?.length; else emptyTemplate">
        <div
          class="item"
          #parent
          *ngFor="let item of $.filteredItems; let idx = index"
          [class.selected]="item.checked"
          [class.most-relevant]="searchable && searchInputControl.value && idx === 0"
        >
          <prizm-checkbox
            [ngModel]="item.checked"
            [ngModelOptions]="{ standalone: true }"
            [checked]="item.checked"
            [host]="parent"
            [indeterminate]="!!item.indeterminate"
            (changed)="select(item)"
          >
            <div
              class="text"
              #elem
              *prizmLet="item | prizmCallFunc : stringify : $any(emptyContent) as text"
              [prizmHint]="text"
              [prizmHintDirection]="direction"
              [prizmHintCanShow]="$any(elem | prizmCallFunc : prizmIsTextOverflow$ | async)"
            >
              <ng-container
                *polymorphOutlet="
                  valueTemplate as content;
                  context: {
                    $implicit: {
                      obj: item.obj,
                      checked: item.checked,
                      stringify: item | prizmCallFunc : stringify
                    }
                  } as context
                "
              >
                {{ text }}
              </ng-container>
            </div>
          </prizm-checkbox>
        </div>
      </ng-container>
      <ng-template #emptyTemplate>
        <ng-container *polymorphOutlet="emptyContent as data">
          <div class="empty-template">{{ emptyContent }}</div>
        </ng-container>
      </ng-template>
    </prizm-data-list>
  </ng-template>
</prizm-dropdown-host>
