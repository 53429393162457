<prizm-dropdown-host
  class="z-hosted"
  #prizmDropdownHostComponent
  [style.--prizm-dropdown-host-width]="'100%'"
  [canOpen]="interactive"
  [content]="openTimeTemplate ? dropdownTimeTemplate : dropdownDateTemplate"
  [prizmDropdownHostWidth]="openTimeTemplate ? '100%' : 'auto'"
  [isOpen]="interactive && (openTimeTemplate || open)"
  [closeByEsc]="true"
  (isOpenChange)="onOpenChange($event); $event && prizmDropdownHostComponent.reCalculatePositions()"
>
  <prizm-input-layout [label]="label" [outer]="outer" [forceClear]="forceClear" [size]="size">
    <ng-template prizmInputStatusText>Ошибка! Неправильный формат</ng-template>
    <input
      class="input-search"
      #focusableElementRef
      [mask]="textMaskOptions"
      [showMaskTyped]="focusableElementRef.focused"
      [dropSpecialCharacters]="false"
      [readonly]="readOnly"
      [placeholder]="placeholder"
      [disabled]="computedDisabled"
      [formControl]="innerControl"
      prizmInput
    />
    <!--    [ngModel]="computedValue"-->
    <!--    (ngModelChange)="onValueChange($event || '')"-->

    <!--    [patterns]="fixedPatternForTime"-->
    <ng-container prizm-input-right>
      <button
        [interactive]="true"
        (click)="openDateDropdown()"
        prizmInputIconButton="date-calendar-blank"
      ></button>
      <button [interactive]="true" (click)="openTimeDropdown()" prizmInputIconButton="date-clock"></button>
      <ng-container *ngFor="let button of rightButtons$ | async" [ngTemplateOutlet]="button.template">
      </ng-container>
    </ng-container>
  </prizm-input-layout>
</prizm-dropdown-host>

<ng-template #dropdownDateTemplate>
  <prizm-calendar
    [min]="calendarMinDay"
    [max]="calendarMaxDay"
    [disabledItemHandler]="disabledItemHandler"
    [month]="computedActiveYearMonth"
    [value]="calendarValue"
    (dayClick)="onDayClick($event)"
    (monthChange)="onMonthChange($event)"
    prizmPreventDefault="mousedown"
    automation-id="prizm-input-date-time__calendar"
  ></prizm-calendar>
</ng-template>

<ng-template #dropdownTimeTemplate>
  <prizm-data-list
    class="block"
    *ngIf="timeItems.length"
    [style.--prizm-data-list-border]="0"
    (prizmAfterViewInit)="prizmDropdownHostComponent.reCalculatePositions()"
    (prizmOnDestroy)="openTimeTemplate = false"
    prizmLifecycle
  >
    <ng-container>
      <div
        class="time-item"
        *ngFor="let item of timeItems; let idx = index"
        [class.selected]="value?.[1] && item.isSameTime($any(value?.[1]))"
        (click)="$event.stopPropagation(); onTimeMenuClick(item, $event)"
      >
        <span class="text">
          {{ item }}
        </span>
      </div>
    </ng-container>
  </prizm-data-list>
</ng-template>
