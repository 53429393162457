<div class="z-row" *prizmRepeatTimes="let rowIndex of rows" automation-id="prizm-primitive-year-picker__row">
  <ng-container *prizmRepeatTimes="let colIndex of ITEMS_IN_ROW">
    <div
      class="z-cell"
      *prizmLet="getItem(rowIndex, colIndex) as item"
      [class.z-cell_today]="itemIsToday(item.month)"
      [class.z-cell_interval]="itemIsInterval(item.month)"
      [attr.data-range]="getItemRange(item.month)"
      [attr.data-state]="getItemState(item.month)"
      [prizmScrollIntoView]="scrollItemIntoView(item.month)"
      (prizmHoveredChange)="onItemHovered($event, item.month)"
      (prizmPressedChange)="onItemPressed($event, item.month)"
      (click)="onItemClick($event, item.month)"
      automation-id="prizm-primitive-year-picker__cell"
    >
      <div class="z-item">{{ item | prizmMonth | async }}</div>
    </div>
  </ng-container>
</div>
