<button
  class="switcher"
  *ngIf="data?.title; else iconBtn"
  [class.switcher_active]="isActive"
  [disabled]="isDisabled"
  [icon]="$any(data?.icon)"
  [size]="size"
  [appearanceType]="data?.appearanceType ?? 'ghost'"
  [appearance]="data?.appearance ?? 'secondary'"
  prizmButton
>
  {{ data?.title }}
</button>

<ng-template #iconBtn>
  <button
    class="switcher switcher_icon"
    [class.switcher_active]="isActive"
    [disabled]="isDisabled"
    [icon]="$any(data?.icon)"
    [size]="size"
    [appearanceType]="data?.appearanceType ?? 'ghost'"
    [appearance]="data?.appearance ?? 'secondary'"
    prizmIconButton
  ></button>
</ng-template>
