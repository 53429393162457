<div class="host" prizmFocusTrap prizmTheme>
  <div class="content">
    <div class="title prizm-font-title-h4">
      <ng-container *polymorphOutlet="$any(context.title) as data; context: context">
        {{ data }}
      </ng-container>
    </div>
    <div class="description prizm-font-main-body-14">
      <prizm-scrollbar class="scrollbar">
        <ng-container *polymorphOutlet="$any(context.description) as data; context: $any(context)">
          {{ data }}
        </ng-container>
      </prizm-scrollbar>
    </div>
  </div>

  <div class="footer prizm-font-btn-links-14">
    <ng-template #columnWrapper let-column="column"> </ng-template>

    <ng-container *polymorphOutlet="$any(context.footer); context: context">
      <ng-container [ngSwitch]="!context.showByVertical">
        <div class="horizontal" *ngSwitchCase="true">
          <div class="left">
            <ng-container
              *ngIf="context.supportButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.supportButton }"
            >
            </ng-container>
          </div>
          <div class="right">
            <ng-container
              *ngIf="context.cancelButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.cancelButton }"
            >
            </ng-container>

            <ng-container
              *ngIf="context.confirmButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.confirmButton }"
            >
            </ng-container>
          </div>
        </div>
        <div class="vertical" *ngSwitchDefault>
          <div class="top">
            <ng-container
              *ngIf="context.confirmButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.confirmButton }"
            >
            </ng-container>
            <ng-container
              *ngIf="context.supportButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.supportButton }"
            >
            </ng-container>
          </div>
          <div class="bottom">
            <ng-container
              *ngIf="context.cancelButton"
              [ngTemplateOutlet]="buttonTemplate"
              [ngTemplateOutletContext]="{ button: context.cancelButton }"
            >
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #buttonTemplate let-button="button">
      <button
        [style]="button.style"
        [appearance]="button.appearance"
        [appearanceType]="button.appearanceType"
        [size]="button.size"
        [iconRight]="button.iconRight"
        [icon]="button.icon"
        [disabled]="$any(button.disabled ?? false | prizmToObservable | async)"
        [showLoader]="$any(button.showLoader ?? false | prizmToObservable | async)"
        (click)="button.action(context)"
        prizmButton
      >
        {{ button.text }}
      </button>
    </ng-template>
  </div>
</div>
