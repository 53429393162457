<tui-theme-night *ngIf="night"></tui-theme-night>
<tui-root>
  <div class="prizm-doc-page">
    <prizm-doc-navigation class="prizm-doc-navigation">
      <ng-container prizmPreHeader>
        <ng-content select="[prizmPreNavigationHeader]"></ng-content>
      </ng-container>
      <ng-content select="tuiDocNavigation"></ng-content>
    </prizm-doc-navigation>
    <div class="prizm-doc-content" (prizmAfterViewInit)="contentReady.emit($event.nativeElement)">
      <router-outlet class="tui-doc-outlet"></router-outlet>
    </div>
  </div>
  <!--    <header prizmDocHeader>-->
  <!--        <label class="night-mode">-->
  <!--            Night mode-->
  <!--            <tui-toggle-->
  <!--                class="tui-toggle"-->
  <!--                [ngModel]="night"-->
  <!--                (ngModelChange)="onMode($event)"-->
  <!--            ></tui-toggle>-->
  <!--        </label>-->
  <!--        <ng-content select="prizmDocHeader"></ng-content>-->
  <!--    </header>-->
  <ng-container ngProjectAs="tuiOverContent">
    <ng-content select="tuiOverContent"></ng-content>
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <ng-content select="tuiOverDialogs"></ng-content>
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <ng-content select="tuiOverAlerts"></ng-content>
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <ng-content select="tuiOverPortals"></ng-content>
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <ng-content select="tuiOverHints"></ng-content>
  </ng-container>
</tui-root>
