<div class="logo-block x-margin">
  <a href="http://prizm.zyfra.com/" target="_blank">
    <img src="/assets/logos/logo-dark.svg" />
  </a>
  <prizm-toggle [ngModel]="isNight$ | async" (ngModelChange)="onMode($event)" iconOn="social-sun">
  </prizm-toggle>
</div>
<div class="sub-text x-margin prizm-font-input-text-14px">
  <span> Индустриальная дизайн-система </span>
  <a class="icon" target="_blank" href="https://github.com/zyfra/Prizm">
    <prizm-icons-svg [size]="24" [name]="githubSvgName"></prizm-icons-svg>
  </a>
</div>
