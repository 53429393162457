<div class="box">
  <div class="year-month prizm-font-input-text-14px">
    <div *ngIf="oneMonth; else buttonMonth">
      {{ value | prizmMonth | async }}
    </div>

    <div *ngIf="oneYear; else button">
      {{ value.formattedYear }}
    </div>
  </div>
  <div>
    <prizm-primitive-spin-button
      [class.hidden]="hideButtons"
      [focusable]="false"
      [leftDisabled]="prevMonthDisabled"
      [rightDisabled]="nextMonthDisabled"
      (leftClick)="onPrevMonthClick()"
      (rightClick)="onNextMonthClick()"
    >
    </prizm-primitive-spin-button>
  </div>
</div>

<ng-template #button>
  <div class="year-box" (click)="onYearClick($event)">
    <button
      class="pagination-btn"
      [attr.data-testid]="'prizm-primitive-year-month-pagination__year-button'"
      [prizmFocusable]="true"
      [class.active]="yearActive"
      type="button"
    >
      {{ value.formattedYear }}
      <span>
        <prizm-icon iconClass="chevrons-dropdown-small"></prizm-icon>
      </span>
    </button>
  </div>
</ng-template>

<ng-template #buttonMonth>
  <div class="month-box" (click)="onMonthClick($event)">
    <button
      class="pagination-btn"
      [attr.testId]="'prizm-primitive-year-month-pagination__year-button'"
      [class.active]="monthActive"
      [prizmFocusable]="true"
      type="button"
    >
      {{ value | prizmMonth | async }}
      <span>
        <prizm-icon iconClass="chevrons-dropdown-small"></prizm-icon>
      </span>
    </button>
  </div>
</ng-template>
