export const Icon16Defs = [
  {
    dir: 'account',
    data: [
      'account-card-details',
      'account-circle',
      'account-contact-sync',
      'account-done',
      'account-down-list-open',
      'account-down-list',
      'account-edit',
      'account-group',
      'account-key',
      'account-location',
      'account-lock',
      'account-log-in',
      'account-logout',
      'account-minus',
      'account-multiple-minus',
      'account-multiple-plus',
      'account-multiple-shield',
      'account-plus',
      'account-remove',
      'account-role',
      'account-search',
      'account-setting-1',
      'account-setting',
      'account-shield-1',
      'account-shield-alarm',
      'account-shield-off',
      'account-shield',
      'account-switch',
      'account-unlock',
      'account-worker',
      'account',
    ],
  },
  {
    dir: 'add',
    data: [
      'add-alarm',
      'add-bell-plus',
      'add-bookmark-plus',
      'add-cart-plus',
      'add-circle-fill',
      'add-circle',
      'add-file-plus',
      'add-folder-plus',
      'add-label-add',
      'add-language',
      'add-library-plus',
      'add-line-add',
      'add-map-marker-plus',
      'add-phone',
      'add-plus-box-fill',
      'add-plus-box',
      'add-plus-circle-multiple',
      'add-plus-drop-down-list',
      'add-plus',
      'add-shape-square-plus',
    ],
  },
  {
    dir: 'alerts',
    data: [
      'alerts-alarm-light',
      'alerts-bell-fill',
      'alerts-bell-off',
      'alerts-bell-ring-fill',
      'alerts-bell-ring',
      'alerts-bell',
      'alerts-box-fill',
      'alerts-circle-fill',
      'alerts-circle-mini-1',
      'alerts-circle-mini',
      'alerts-circle',
      'alerts-decagram-fill',
      'alerts-decagram',
      'alerts-false_alerts1',
      'alerts-false_alerts2',
      'alerts-flag-fill',
      'alerts-flag',
      'alerts-help-box-fill',
      'alerts-help-box',
      'alerts-help-circle-outline',
      'alerts-help-fill',
      'alerts-info-add-incongruity',
      'alerts-info-circle-fill',
      'alerts-info-square-fill',
      'alerts-info-square',
      'alerts-info-triangle-fill',
      'alerts-info-triangle',
      'alerts-inform-fill-mini',
      'alerts-inform-mini',
      'alerts-inform',
      'alerts-information-variant',
      'alerts-mark',
      'alerts-octagon-fill',
      'alerts-octagon',
      'alerts-pulse',
      'alerts-question-mark',
      'alerts-square',
      'alerts-warning',
      'alerts',
      'success-circle-fill',
      'success-circle-outline',
      'success-decagram-fill',
      'success-decagram-outline',
    ],
  },
  {
    dir: 'arrows',
    data: [
      'arrows-all',
      'arrows-arrow-circle-down',
      'arrows-arrow-circle-left',
      'arrows-arrow-circle-right',
      'arrows-arrow-circle-up',
      'arrows-arrow-expand-horizontal',
      'arrows-arrow-expand-vertical',
      'arrows-arrow-left',
      'arrows-arrow-right',
      'arrows-arrow-up-level',
      'arrows-autorenew-1',
      'arrows-autorenew-circle',
      'arrows-autorenew',
      'arrows-back',
      'arrows-cached-1',
      'arrows-cached',
      'arrows-chevron-left',
      'arrows-chevron-right',
      'arrows-collapse-four-arrows',
      'arrows-collapse',
      'arrows-compare',
      'arrows-corner-chek-in',
      'arrows-corner-down-left',
      'arrows-corner-down-right',
      'arrows-corner-left-down',
      'arrows-corner-left-up',
      'arrows-corner-right-down',
      'arrows-corner-right-up',
      'arrows-corner-up-left',
      'arrows-corner-up-right',
      'arrows-diagonal-arrow-left-down',
      'arrows-diagonal-arrow-left-up',
      'arrows-diagonal-arrow-right-down',
      'arrows-diagonal-arrow-right-up',
      'arrows-down-2',
      'arrows-down-bold',
      'arrows-down-double-2',
      'arrows-down-double-top-2',
      'arrows-down-limit-1',
      'arrows-down-limit-double-1',
      'arrows-down',
      'arrows-download',
      'arrows-expand-all',
      'arrows-expand',
      'arrows-left-bold',
      'arrows-next',
      'arrows-refresh',
      'arrows-replay',
      'arrows-right-bold',
      'arrows-shuffle-variant',
      'arrows-shuffle',
      'arrows-swap-horiz-2',
      'arrows-swap-horiz',
      'arrows-swap-vert-2',
      'arrows-swap-vert',
      'arrows-up-2',
      'arrows-up-bold',
      'arrows-up-double-2',
      'arrows-up-double-top-2',
      'arrows-up-limit-1',
      'arrows-up-limit-double-1',
      'arrows-up',
    ],
  },
  {
    dir: 'audio',
    data: [
      'audio-add-library-music',
      'audio-artist',
      'audio-bookmark-music',
      'audio-file-music',
      'audio-microphone-off',
      'audio-microphone',
      'audio-music-box',
      'audio-music-circle',
      'audio-music-off',
      'audio-music',
      'audio-volume-high',
      'audio-volume-low',
      'audio-volume-medium',
      'audio-volume-minus',
      'audio-volume-mute',
      'audio-volume-off',
      'audio-volume-plus',
    ],
  },
  {
    dir: 'bages',
    data: [
      'badges-clipboard-tex',
      'badges-clipboard',
      'badges-code-download',
      'badges-code',
      'badges-tag-badges-tag-archve',
      'badges-tag-fill',
      'badges-tag-multiple',
      'badges-tag-plus',
      'badges-tag-remove',
      'badges-tag-search',
      'badges-tag-with-an-exclamation-mark',
      'badges-tag-with-binding-to-data',
      'badges-tag-without-binding-to-data',
      'badges-tag',
    ],
  },
  {
    dir: 'battery',
    data: [
      'battery-10',
      'battery-30',
      'battery-50',
      'battery-80',
      'battery-alert',
      'battery-charging',
      'battery-flash-off',
      'battery-flash-ok',
      'battery-flash',
      'battery-minus',
      'battery-plus',
      'battery',
    ],
  },
  {
    dir: 'bookmarks',
    data: [
      'bookmarks-book-open-page-variant',
      'bookmarks-book-open-plus',
      'bookmarks-book-open-re-1',
      'bookmarks-book-open-re-2',
      'bookmarks-book-open-re',
      'bookmarks-book-open-variant',
      'bookmarks-book-open',
      'bookmarks-book',
      'bookmarks-bookmark-check-fill',
      'bookmarks-bookmark-check',
      'bookmarks-bookmark-fill',
      'bookmarks-bookmark-music-fill',
      'bookmarks-bookmark-music',
      'bookmarks-bookmark-plus-fill',
      'bookmarks-bookmark-plus',
      'bookmarks-bookmark-remove-2',
      'bookmarks-bookmark-remove-fill',
      'bookmarks-bookmark-remove',
      'bookmarks-bookmark',
    ],
  },
  {
    dir: 'camera',
    data: [
      'camera-camera',
      'camera-clapperboard-close',
      'camera-clapperboard',
      'camera-close',
      'camera-film-close',
      'camera-film',
      'camera-image',
      'camera-video-close',
      'camera-video-off',
      'camera-video',
      'camera',
    ],
  },
  {
    dir: 'cancel',
    data: [
      'cancel-block',
      'cancel-close-box-fill',
      'cancel-close-box',
      'cancel-close-circle-fill',
      'cancel-close-circle',
      'cancel-close',
      'cancel-delete-line',
      'cancel-delete-content',
      'cansel-delete-file',
    ],
  },
  {
    dir: 'charts',
    data: [
      'charts-arc-1',
      'charts-arc',
      'charts-areaspline',
      'charts-bar-graph',
      'charts-bar-stacked',
      'charts-bar',
      'charts-bubble-line',
      'charts-bubble',
      'charts-chart-donut-variant-half',
      'charts-chart-donut-variant',
      'charts-discharge',
      'charts-donut',
      'charts-elevation-decline',
      'charts-elevation-rise',
      'charts-finance',
      'charts-gantt',
      'charts-google-analytics',
      'charts-google-assistant',
      'charts-hexagon-multiple',
      'charts-histogram-chart-dashed-2',
      'charts-histogram-chart-dashed',
      'charts-histogram',
      'charts-line-variant',
      'charts-pie-1',
      'charts-pie-2',
      'charts-pie',
      'charts-poll-box',
      'charts-poll',
      'charts-scatterplot-hexbin',
      'charts-signal',
      'charts-smart-trend',
      'charts-trending-down',
      'charts-trending-up',
    ],
  },
  {
    dir: 'chevrons',
    data: [
      'chevron-middle',
      'chevrons-close-all',
      'chevrons-double-down',
      'chevrons-double-left-mini',
      'chevrons-double-left',
      'chevrons-double-right-mini',
      'chevrons-double-right',
      'chevrons-double-up',
      'chevrons-down',
      'chevrons-dropdown-small',
      'chevrons-dropdown',
      'chevrons-dropup',
      'chevrons-input',
      'chevrons-left-mini',
      'chevrons-left',
      'chevrons-menu-left-small',
      'chevrons-menu-left',
      'chevrons-menu-right',
      'chevrons-open-all',
      'chevrons-right-mini',
      'chevrons-right',
      'chevrons-unfold-less-horizontal',
      'chevrons-unfold-less-verica',
      'chevrons-unfold-more-horizontal',
      'chevrons-unfold-more-vertical',
      'chevrons-up',
    ],
  },
  {
    dir: 'date-calendar',
    data: [
      'date-access-alarm',
      'date-asterisk',
      'date-calendar-blank',
      'date-calendar-check',
      'date-calendar-clock',
      'date-calendar-plus',
      'date-calendar-question',
      'date-calendar-range',
      'date-calendar-remove',
      'date-calendar-text',
      'date-calendar-today',
      'date-calendar',
      'date-clock-alert',
      'date-clock-end',
      'date-clock-in',
      'date-clock-out',
      'date-clock-start',
      'date-clock',
      'date-day',
      'date-history',
      'date-hour',
      'date-inform-add',
      'date-inform-delete',
      'date-midnight',
      'date-minute',
      'date-month',
      'date-now',
      'date-period-dropdown-list-open',
      'date-period-dropdown-list',
      'date-range',
      'date-second',
      'date-time-add-2',
      'date-time-add',
      'date-time-remove-2',
      'date-time-remove',
      'date-timer-sand-empty',
      'date-timer',
      'date-update',
      'date-year',
    ],
  },
  {
    dir: 'delete',
    data: [
      'delete-do-not-disturb',
      'delete-drop',
      'delete-empty',
      'delete-forever',
      'delete-label-delete',
      'delete-minus-circle',
      'delete-minus-square',
      'delete-minus',
      'delete-restore',
      'delete',
    ],
  },
  {
    dir: 'devices',
    data: [
      'devices-android-debug-bridge',
      'devices-android',
      'devices-camera-front',
      'devices-cellphone-basic',
      'devices-cellphone-link-off',
      'devices-cellphone-link',
      'devices-cellphone-wireless',
      'devices-cellphone',
      'devices-desktop-tower',
      'devices-hard-drive-outline',
      'devices-headphones',
      'devices-input-device',
      'devices-laptop-off',
      'devices-laptop',
      'devices-monitor',
      'devices-printer-dropdown-list',
      'devices-printer',
      'devices-sensor',
      'devices-speaker',
      'devices-tablet',
      'devices-touchpad',
      'devices-transmitter',
      'devices-tv',
      'devices-watch-variant',
    ],
  },
  {
    dir: 'editor',
    data: [
      'editor-add-column',
      'editor-add-row',
      'editor-alfavit',
      'editor-attach-2',
      'editor-attach-file',
      'editor-auto',
      'editor-auto1',
      'editor-border-all',
      'editor-border-bottom',
      'editor-border-clear',
      'editor-border-color',
      'editor-border-horizontal',
      'editor-border-inner',
      'editor-border-left',
      'editor-border-outer',
      'editor-border-right',
      'editor-border-style',
      'editor-border-top',
      'editor-border-vertical',
      'editor-brace-left',
      'editor-brace-right',
      'editor-copy-column',
      'editor-copy-drop',
      'editor-copy-row',
      'editor-copy',
      'editor-crop',
      'editor-cross-out',
      'editor-curly-brace-left',
      'editor-curly-brace-right',
      'editor-delete-column',
      'editor-delete-row',
      'editor-disconnect-link',
      'editor-discrete-set-1',
      'editor-discrete-set',
      'editor-division',
      'editor-dots',
      'editor-down-1',
      'editor-down',
      'editor-drag-and-drop',
      'editor-dropdown-list-open',
      'editor-dropdown-list',
      'editor-droplet',
      'editor-format-align-center',
      'editor-format-align-justify',
      'editor-format-align-left',
      'editor-format-align-right',
      'editor-format-bold',
      'editor-format-color-fill',
      'editor-format-color-reset',
      'editor-format-color-text',
      'editor-format-indent',
      'editor-format-indent1',
      'editor-format-italic',
      'editor-format-list-bulleted',
      'editor-format-list-numbered',
      'editor-format-textdirection-l-to-r',
      'editor-format-underlined',
      'editor-formula',
      'editor-functions',
      'editor-group-choose',
      'editor-group-dropdown',
      'editor-group-panel',
      'editor-group',
      'editor-hash',
      'editor-horizontal-align-center-',
      'editor-insert-emoticon-sad',
      'editor-insert-emoticon',
      'editor-insert-link-add',
      'editor-insert-link-delete',
      'editor-insert-link',
      'editor-line-height',
      'editor-link',
      'editor-list-dushed-2',
      'editor-merge-type',
      'editor-mini',
      'editor-minus',
      'editor-mode',
      'editor-multiplication',
      'editor-panel-down',
      'editor-panel-left',
      'editor-panel-right',
      'editor-panel-top',
      'editor-percent-1',
      'editor-percent',
      'editor-plus',
      'editor-right-1',
      'editor-right',
      'editor-screen',
      'editor-search-hierarchy',
      'editor-search-tags-words',
      'editor-sort-asc',
      'editor-subskrit-text',
      'editor-superskrit-text',
      'editor-table-lookup',
      'editor-table',
      'editor-tag',
      'editor-template',
      'editor-title',
      'editor-transpose-right',
      'editor-transpose-top',
      'editor-transpose',
      'editor-ungroup',
      'editor-vertical-align-bottom',
      'editor-vertical-align-center 2',
      'editor-vertical-align-center',
      'editor-vertical-align-top',
      'editor-window',
      'editor-wrap-text',
    ],
  },
  {
    dir: 'files',
    data: [
      'export-files-csv',
      'export-files-pdf',
      'export-files-sap',
      'export-files-xlsx',
      'files-alarm',
      'files-archive',
      'files-box',
      'files-cloud-check',
      'files-cloud',
      'files-cloup-upload',
      'files-collection-2',
      'files-confirmation',
      'files-correct',
      'files-description-properties',
      'files-document-image',
      'files-document-text',
      'files-excel-box',
      'files-export-1',
      'files-export-arr',
      'files-export',
      'files-file-close',
      'files-file-doc',
      'files-file-docx',
      'files-file-open',
      'files-file-pdf',
      'files-file-play',
      'files-file-ppt',
      'files-file-pptx',
      'files-file-remove',
      'files-file-txt',
      'files-file-video',
      'files-file-xls',
      'files-file-xlsx',
      'files-file',
      'files-find-1',
      'files-find',
      'files-folder-1',
      'files-folder-add',
      'files-folder-close',
      'files-folder-delete',
      'files-folder-mode',
      'files-folder-open-1',
      'files-folder-open',
      'files-folder-remove',
      'files-folder-root-add',
      'files-folder-root-remove',
      'files-folder',
      'files-import-1',
      'files-import-arr',
      'files-import',
      'files-in-group',
      'files-incorrect',
      'files-indicator',
      'files-loader',
      'files-method',
      'files-mnemo',
      'files-mode',
      'files-multi',
      'files-norm',
      'files-plus',
      'files-powerpoint',
      'files-specification',
      'files-view-document',
      'files-word-box',
      'files-просмотр',
      'import-files-1с',
      'import-files-csv',
      'import-files-pdf',
      'import-files-xlsx',
    ],
  },
  {
    dir: 'finance',
    data: [
      'finance-barcode',
      'finance-calculator-auto',
      'finance-calculator',
      'finance-credit-card-of',
      'finance-credit-card-plus',
      'finance-credit-card',
      'finance-currency-btc',
      'finance-currency-usd-off',
      'finance-currency-usd',
      'finance-qr-code-auto-1',
      'finance-qr-code-auto-2',
      'finance-qr-code-auto-3',
      'finance-qr-code-search',
      'finance-qr-code',
      'finance-scanner',
      'finance-wallet',
    ],
  },
  {
    dir: 'location',
    data: [
      'location-compass',
      'location-crosshairs-gps',
      'location-crosshairs',
      'location-language',
      'location-localization-down-list',
      'location-map-2',
      'location-map-marker-date-1',
      'location-map-marker-date-2',
      'location-map-marker-minus',
      'location-map-marker-off',
      'location-map-marker-plus',
      'location-map-marker',
      'location-map',
      'location-navigation-1',
      'location-navigation',
      'location-person-pin-circle',
      'location-pin-1',
      'location-pin-2',
      'location-pin',
    ],
  },
  {
    dir: 'messaging',
    data: [
      'messaging-add-comment',
      'messaging-alert',
      'messaging-at',
      'messaging-emai-down-list',
      'messaging-emai',
      'messaging-email-open',
      'messaging-forward',
      'messaging-inbox',
      'messaging-message-circle',
      'messaging-message-off',
      'messaging-message',
      'messaging-processing',
      'messaging-question-ask',
      'messaging-reply',
      'messaging-send',
      'messaging-text',
    ],
  },
  {
    dir: 'network',
    data: [
      'network-close',
      'network-cloud-download',
      'network-cloud-upload',
      'network-database-administration',
      'network-database-delete',
      'network-database-json',
      'network-database-minus',
      'network-database-plus',
      'network-database-search',
      'network-database-sql',
      'network-database',
      'network-download',
      'network-ethernet-open',
      'network-external-link',
      'network-lan-connect',
      'network-lan-disconnect',
      'network-lan-pending',
      'network-lan',
      'network-layers',
      'network-library',
      'network-minus',
      'network-no-layers',
      'network-object',
      'network-plus',
      'network-scheme-2',
      'network-scheme',
      'network-security',
      'network-server-1',
      'network-server-network-off',
      'network-server-network',
      'network-server-off',
      'network-server-security',
      'network-server',
      'network-upload',
    ],
  },
  {
    dir: 'phone',
    data: [
      'phone-call-missed',
      'phone-in-talk',
      'phone-minus',
      'phone-missed',
      'phone-off',
      'phone-paused',
      'phone-plus',
      'phone',
    ],
  },
  {
    dir: 'player',
    data: [
      'player-loop',
      'player-pause',
      'player-play-back',
      'player-play',
      'player-skip-backward',
      'player-skip-forward',
      'player-skip-next-circle-outline',
      'player-skip-previous',
      'player-stop',
      'player-voicemail',
    ],
  },
  {
    dir: 'poi',
    data: [
      'burn',
      'cargo-box-2',
      'cargo-box',
      'cargo-container',
      'cargo',
      'fire',
      'freeze',
      'gas',
      'loading',
      'location-map-marker-minus',
      'poi-achive-Mir',
      'poi-article-1',
      'poi-barley',
      'poi-bdrv-administration',
      'poi-boiler-hot-water-gas-and-oil',
      'poi-cancel sample reject',
      'poi-coal',
      'poi-crane',
      'poi-difference',
      'poi-early-version',
      'poi-ecopost-circle',
      'poi-ecopost-rectangle',
      'poi-electrics',
      'poi-factory pipe',
      'poi-factory-pipes',
      'poi-factory',
      'poi-fan',
      'poi-flask-1',
      'poi-flask-2',
      'poi-flask-3',
      'poi-flask-4',
      'poi-flask-5',
      'poi-fuel-oil-tank',
      'poi-fuel',
      'poi-gas-balloon',
      'poi-gas-monitoring',
      'poi-gas-station',
      'poi-gear-cooling',
      'poi-generator',
      'poi-git-lab',
      'poi-heat-exchanger',
      'poi-heat-recovery-boiler',
      'poi-high-speed-reduction-cooling',
      'poi-lng-storage',
      'poi-medicine',
      'poi-ni-fi',
      'poi-nuclear-station',
      'poi-oil-factory',
      'poi-oil-field',
      'poi-oil-storage',
      'poi-oil-tank',
      'poi-owl',
      'poi-pipe-sensor',
      'poi-pipe-valve',
      'poi-pipeline',
      'poi-plus',
      'poi-press',
      'poi-pump-drop',
      'poi-pump',
      'poi-respirator-2.1',
      'poi-respirator-2',
      'poi-respirator-3',
      'poi-respirator-4',
      'poi-respirator-5',
      'poi-respirator',
      'poi-separator',
      'poi-steam-boiler',
      'poi-steam-turbine',
      'poi-steam',
      'poi-strit',
      'poi-tank',
      'poi-water-heater',
      'poi-water-pump',
      'product-grade-according-to-specification',
      'snowflake',
    ],
  },
  {
    dir: 'selection',
    data: [
      'selection-check-all',
      'selection-check-simple',
      'selection-checkbox-blank-circle',
      'selection-checkbox-blank',
      'selection-checkbox-marked-circle-chanel',
      'selection-checkbox-marked-circle',
      'selection-checked-1',
      'selection-checked-2',
      'selection-checked-empty',
      'selection-checked',
      'selection-choice',
      'selection-halfchoice',
      'selection-process',
      'selection-radio-off',
      'selection-radio-on',
      'selection-success',
      'selection-toggle-left',
      'selection-toggle-right',
      'selection-unchoice',
    ],
  },
  {
    dir: 'settings',
    data: [
      'settings-2',
      'settings-apps',
      'settings-more-horiz',
      'settings-more-vert',
      'settings-power',
      'settings-tune-vertical',
      'settings-tune',
      'settings',
    ],
  },
  {
    dir: 'shapes',
    data: [
      'shapes-Key',
      'shapes-Star',
      'shapes-animation',
      'shapes-arrange-bring-forward',
      'shapes-arrange-send-backward',
      'shapes-arrange-send-to-back',
      'shapes-box-shadow',
      'shapes-checkbox-intermediate',
      'shapes-checkerboard',
      'shapes-collage',
      'shapes-collector',
      'shapes-cube-3',
      'shapes-cube-outline',
      'shapes-cube',
      'shapes-drawing-box',
      'shapes-drawing-outline',
      'shapes-drawing',
      'shapes-hexagon-fill',
      'shapes-hexagon',
      'shapes-layers-off',
      'shapes-layers',
      'shapes-nest-protec',
      'shapes-nut',
      'shapes-ontology-2',
      'shapes-ontology',
      'shapes-pentagon-fill',
      'shapes-pentagon',
      'shapes-rhombus-fill',
      'shapes-rhombus',
      'shapes-shape-1-f',
      'shapes-shape-1',
      'shapes-shape-2-f',
      'shapes-shape-2',
      'shapes-shape-3-f',
      'shapes-shape-3',
      'shapes-shape-4-f',
      'shapes-shape-4',
      'shapes-shape-5-f',
      'shapes-shape-5',
      'shapes-shape-6',
      'shapes-shape-7',
      'shapes-shape-fill',
      'shapes-shape-plus-1',
      'shapes-shape-plus',
      'shapes-shape',
      'shapes-square-inc',
      'shapes-triangle-fill',
      'shapes-triangle',
    ],
  },
  {
    dir: 'shopping',
    data: ['shopping-box', 'shopping-cart', 'shopping-gift', 'shopping'],
  },
  {
    dir: 'signal',
    data: [
      'signal-access-point',
      'signal-bluetooth',
      'signal-cast',
      'signal-off',
      'signal-radio-tower',
      'signal-rss-box',
      'signal-wifi-off',
      'signal-wifi',
      'signal',
    ],
  },
  {
    dir: 'social',
    data: [
      'social-browser',
      'social-globe-2',
      'social-heart-fill',
      'social-heart',
      'social-home-breadcrumbs',
      'social-home',
      'social-instagram',
      'social-moon',
      'social-share',
      'social-star-fill',
      'social-star-half',
      'social-star',
      'social-sun',
      'social-tab',
      'social-telegram',
      'social-thumb-down',
      'social-thumb-up',
    ],
  },
  {
    dir: 'sort',
    data: [
      'sort-abc-ru-up',
      'sort-abc-ru',
      'sort-alphabetical-up',
      'sort-alphabetical',
      'sort-apps',
      'sort-asc-arr',
      'sort-desc-arr',
      'sort-eye-off-2',
      'sort-eye-off',
      'sort-eye',
      'sort-filter-remove',
      'sort-filter-setting',
      'sort-filter',
      'sort-funnel-drop-down-list-open',
      'sort-funnel-drop-down-list',
      'sort-look-into-the-buffer',
      'sort-maximize',
      'sort-numeric',
      'sort-real-size',
      'sort-search-mistake',
      'sort-time-down',
      'sort-time-up',
      'sort-version',
      'sort-zoom-in-dropdown',
      'sort-zoom-in',
      'sort-zoom-out',
    ],
  },
  {
    dir: 'tools',
    data: [
      'tools-auto-fix',
      'tools-award',
      'tools-briefcase',
      'tools-broom',
      'tools-brush',
      'tools-content-cut',
      'tools-content-save-as',
      'tools-content-save',
      'tools-content-unsave',
      'tools-earth-work',
      'tools-eraser',
      'tools-eyedropper',
      'tools-keyboard-close-1',
      'tools-keyboard-close',
      'tools-keyboard',
      'tools-lightbulb',
      'tools-npm',
      'tools-palette',
      'tools-pantone',
      'tools-ruler',
      'tools-save-all',
      'tools-save-back',
      'tools-solver',
      'tools-thermometer-minus',
      'tools-thermometer-plus',
      'tools-thermometer',
      'tools-umbrella',
    ],
  },
  {
    dir: 'transport',
    data: [
      'transport-airplane',
      'transport-ambulance',
      'transport-truck-1',
      'transport-truck-2',
      'transport-truck-3',
      'transport-truck-4',
      'transport-truck-5',
      'transport-truck',
    ],
  },
  {
    dir: 'vectors',
    data: [
      'vectors-arrange-above',
      'vectors-arrange-below',
      'vectors-circle-variant',
      'vectors-circle',
      'vectors-combine-1',
      'vectors-combine',
      'vectors-curve',
      'vectors-default-16',
      'vectors-difference-ab',
      'vectors-difference-ba',
      'vectors-difference',
      'vectors-ellipse',
      'vectors-empty-16',
      'vectors-intersection',
      'vectors-line',
      'vectors-point',
      'vectors-polygon',
      'vectors-polyline',
      'vectors-radius',
      'vectors-rectangle',
      'vectors-selection',
      'vectors-square',
      'vectors-triangle',
    ],
  },
  {
    dir: 'view',
    data: [
      'view-agenda',
      'view-array',
      'view-carousel',
      'view-column',
      'view-component',
      'view-dashboard-variant',
      'view-dashboard',
      'view-day',
      'view-grid-outline',
      'view-grid',
      'view-headline',
      'view-list',
      'view-logo-grey-z',
      'view-logo-s.grey',
      'view-menu-2',
      'view-menu-arrow-down',
      'view-menu-arrow-left',
      'view-menu-arrow-right',
      'view-menu-arrow-up',
      'view-menu',
      'view-module',
      'view-quilt',
      'view-sequential',
    ],
  },
];
