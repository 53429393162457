<prizm-dropdown-host
  class="z-hosted"
  [canOpen]="interactive"
  [content]="dropdown"
  [isOpen]="open && interactive"
  [prizmDropdownHost]="layoutComponent.el.nativeElement"
  (isOpenChange)="onOpenChange($event)"
  prizmDropdownHostWidth="auto"
>
  <input
    class="input-search"
    #focusableElementRef
    [placeholder]="placeholder"
    [readonly]="true"
    [disabled]="disabled"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="(value | prizmMapper : formatter | async) || ''"
    (ngModelChange)="onValueChange($event || '')"
    prizmInput
  />
  <ng-template #dropdown>
    <prizm-calendar-month
      [(year)]="activeYear"
      [disabledItemHandler]="disabledItemHandler"
      [min]="min"
      [max]="max"
      [value]="value"
      (prizmAfterViewInit)="markAsTouched()"
      (monthClick)="onMonthClick($event)"
      prizmPreventDefault="mousedown"
    ></prizm-calendar-month>
  </ng-template>
</prizm-dropdown-host>
<ng-container *prizmInputLayoutRight>
  <ng-container *polymorphOutlet="calendarIcon as data">
    <button
      [prizmInputIconButton]="$any(data)"
      [interactive]="true"
      [disabled]="disabled"
      (click)="onOpenChange(!open)"
    ></button>
  </ng-container>

  <ng-container *ngFor="let button of rightButtons$ | async" [ngTemplateOutlet]="button.template">
  </ng-container>
</ng-container>
