<prizm-dropdown-host
  class="z-wrapper"
  [canOpen]="interactive && !!filtered.length"
  [content]="dropdownContent"
  [prizmDropdownHostWidth]="'100%'"
  [isOpen]="interactive && open && !!filtered.length"
  [closeByEsc]="true"
  (isOpenChange)="onOpen($event)"
>
  <prizm-input-layout
    [label]="label"
    [outer]="outer"
    [forceClear]="forceClear"
    [size]="size"
    (click)="safeOpenModal()"
  >
    <input
      class="input-search"
      #focusableElementRef
      [mask]="textMaskOptions"
      [showMaskTyped]="focusableElementRef.focused"
      [dropSpecialCharacters]="false"
      [required]="readOnly"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="computedValue"
      (ngModelChange)="onValueChange($event || '')"
      (keydown.arrowUp)="onArrowUp($event)"
      (keydown.arrowDown)="onArrowDown($event)"
      prizmInput
    />
    <ng-container prizm-input-right>
      <button [interactive]="true" (click)="onOpen(!open)" prizmInputIconButton="date-clock"></button>
      <ng-container *ngFor="let button of rightButtons$ | async" [ngTemplateOutlet]="button.template">
      </ng-container>
    </ng-container>
  </prizm-input-layout>
</prizm-dropdown-host>

<ng-template #dropdownContent>
  <prizm-data-list class="block" [style.--prizm-data-list-border]="0">
    <ng-container *ngIf="items?.length">
      <div
        class="item"
        *ngFor="let item of filtered; let idx = index"
        [class.selected]="value && item.isSameTime(value)"
        (click)="onMenuClick(item)"
      >
        <span class="text">
          {{ item }}
        </span>
      </div>
    </ng-container>
  </prizm-data-list>
</ng-template>
