<prizm-doc-page class="info-page" header="Как поставить задачу">
  <p>
    В статье «<a href="https://jira.zyfra.com/wiki/pages/viewpage.action?pageId=179801382"
      >Способы связи с командой Prizm</a
    >» (Confluence) / <a href="{{ storybookBaseUrl }}/about-prizm/contacts">Связаться с командой</a> мы
    описали возможные варианты:
  </p>
  <ol class="decimal">
    <li>
      С помощью Issue (способ описан на странице
      <a href="{{ storybookBaseUrl }}/how-to-work/contributing">Сontributing</a>)
    </li>
  </ol>
  <p class="italic">
    Поставить задачу на изменение можно (issue) в нашем репозитории. Пожалуйста, когда ставите задачу, следите
    за форматом: так мы быстрее поймём, в чём дело, и сможем передать задачу нужному человеку.
  </p>

  <h4>Комментарий</h4>
  <p>Обязательно напишите комментарий. Мы будем рады:</p>
  <ol class="disc">
    <li>подробному описанию задачи</li>
    <li>скриншотам и любым другим изображениям</li>
  </ol>
  <p>Если изображения не прикрепляются к задаче, прикрепите архив или поставьте ссылку на облако.</p>
  <p>
    Объём любой, главное — понятно и по-человечески. Хочется, чтобы в любой момент и автору, и исполнителю
    было понятно, что делать.
  </p>
</prizm-doc-page>
