<ng-container *ngFor="let item of menuItems">
  <prizm-navigation-item-simple
    *ngIf="!item?.children"
    [data]="$any(item)"
    [deep]="1"
  ></prizm-navigation-item-simple>

  <prizm-navigation-item-expandable
    *ngIf="$any(item?.children?.length) > 0"
    [data]="$any(item)"
    [deep]="1"
  ></prizm-navigation-item-expandable>
</ng-container>
