<div class="prizm-navigation-menu-search">
  <input
    class="prizm-navigation-menu-search__input"
    #searchInput
    [formControl]="searchFormControl"
    [placeholder]="placeholder || ''"
  />
  <button
    class="prizm-navigation-menu-search__button"
    [size]="'m'"
    [icon]="'cancel-close'"
    [appearance]="'secondary'"
    [appearanceType]="'ghost'"
    (click)="clearSearchValue()"
    prizmIconButton
  ></button>
</div>
