<prizm-dropdown-host
  class="z-hosted"
  [canOpen]="canOpen"
  [content]="dropdown"
  [closeByEsc]="true"
  [isOpen]="open && canOpen"
  (isOpenChange)="onOpenChange($event)"
  prizmDropdownHostWidth="auto"
>
  <prizm-input-layout [forceClear]="forceClear" [label]="label" [outer]="outer" [size]="size">
    <input
      class="input-search"
      #focusableElementRef
      [mask]="computedMask"
      [showMaskTyped]="focusableElementRef.focused"
      [dropSpecialCharacters]="false"
      [readOnly]="readOnly"
      [placeholder]="placeholder"
      [disabled]="computedDisabled"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="stringValue"
      (ngModelChange)="onValueChange($event || '')"
      prizmInput
    />

    <ng-container prizm-input-right>
      <button
        [interactive]="true"
        (click)="focusableElementRef.focus()"
        prizmInputIconButton="date-calendar-range"
      ></button>
    </ng-container>
  </prizm-input-layout>
  <ng-template #dropdown>
    <prizm-calendar-range
      [style.--prizm-input-layout-width]="'100%'"
      [style.--prizm-dropdown-host-width]="'100%'"
      [defaultViewedMonth]="defaultViewedMonth"
      [disabledItemHandler]="disabledItemHandler"
      [items]="items"
      [min]="$any(min)"
      [max]="$any(max)"
      [markerHandler]="markerHandler"
      [minLength]="minLength"
      [maxLength]="maxLength"
      [value]="value"
      (valueChange)="onRangeChange($event)"
    >
      <ng-container footerFrom>
        <ng-template [ngTemplateOutlet]="$any(footerToTemplate)"> ></ng-template>
      </ng-container>
      <ng-container footerTo>
        <ng-template [ngTemplateOutlet]="$any(footerFromTemplate)"> ></ng-template>
      </ng-container>
    </prizm-calendar-range>
  </ng-template>
</prizm-dropdown-host>
