<div class="blocks">
  <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.every)">
    <label
      class="every value-block block"
      *prizmLet="cronUiState.TYPES.every as currentType"
      [class.selected]="cronUiState.typeControl.value === currentType"
    >
      <div class="radio">
        <prizm-radio-button
          [formControl]="cronUiState.typeControl"
          [label]="null"
          [value]="currentType"
          [name]="'type'"
          (changeEvent)="cronUiState.setEvery()"
        ></prizm-radio-button>
      </div>
      <div class="content row">Каждый час</div>
    </label>
  </ng-container>

  <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.after)">
    <label
      class="block row"
      *prizmLet="cronUiState.TYPES.after as currentType"
      [class.selected]="cronUiState.typeControl.value === currentType"
    >
      <div class="radio">
        <prizm-radio-button
          [formControl]="cronUiState.typeControl"
          [label]="null"
          [value]="currentType"
          [name]="'type'"
          (changeEvent)="cronUiState.updateOn()"
        ></prizm-radio-button>
      </div>
      <div class="content row">
        <span>Каждый</span>
        <prizm-cron-carousel
          [content]="cronUiState.list | prizmPluck : ['everyChosenTimesAfterChosen', 'on']"
          [value]="cronUiState.state$ | async | prizmPluck : ['everyChosenTimesAfterChosen', 'on']"
          (valueChange)="cronUiState.updateOn({ on: $event })"
        >
          <ng-template #content let-value="content">
            {{ value }}
          </ng-template>
        </prizm-cron-carousel>
        <span>час, начиная с</span>

        <prizm-cron-carousel
          [content]="cronUiState.list | prizmPluck : ['everyChosenTimesAfterChosen', 'after']"
          [value]="cronUiState.state$ | async | prizmPluck : ['everyChosenTimesAfterChosen', 'after']"
          (valueChange)="cronUiState.updateOn({ after: $event })"
        ></prizm-cron-carousel>
      </div>
    </label>
  </ng-container>

  <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.specified)">
    <label
      class="block"
      *prizmLet="cronUiState.TYPES.specified as currentType"
      [class.selected]="cronUiState.typeControl.value === currentType"
    >
      <div class="radio">
        <prizm-radio-button
          [formControl]="cronUiState.typeControl"
          [label]="null"
          [value]="currentType"
          [name]="'type'"
          (changeEvent)="scheduleComponent.emit()"
        ></prizm-radio-button>
      </div>
      <div class="content column">
        <span>Выбранный час (выбор одного или нескольких)</span>
        <prizm-cron-schedule
          #scheduleComponent
          [items]="cronUiState.list.specified"
          [selected]="cronUiState.state$ | async | prizmPluck : ['specified']"
          (selectedChange)="cronUiState.updateSpecified($event)"
        ></prizm-cron-schedule>
      </div>
    </label>
  </ng-container>

  <ng-container *ngIf="cronUiState.canShowCronListItem(specifiedList, cronUiState.TYPES.between)">
    <label
      class="block row"
      *prizmLet="cronUiState.TYPES.between as currentType"
      [class.selected]="cronUiState.typeControl.value === currentType"
    >
      <div class="radio">
        <prizm-radio-button
          [formControl]="cronUiState.typeControl"
          [label]="null"
          [value]="currentType"
          [name]="'type'"
          (changeEvent)="cronUiState.updateBetween()"
        ></prizm-radio-button>
      </div>
      <div class="content">
        <span>Каждый час с</span>
        <prizm-cron-carousel
          #start
          [content]="cronUiState.list.between.start"
          [value]="cronUiState.state$ | async | prizmPluck : ['between', 'start']"
          (valueChange)="cronUiState.updateBetween({ start: $event })"
        ></prizm-cron-carousel>
        <span>до</span>
        <prizm-cron-carousel
          #end
          [content]="cronUiState.list.between.end"
          [value]="cronUiState.state$ | async | prizmPluck : ['between', 'end']"
          (valueChange)="cronUiState.updateBetween({ end: $event })"
        ></prizm-cron-carousel>
      </div>
    </label>
  </ng-container>
</div>
