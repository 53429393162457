<prizm-doc-page class="info-page" header="О дизайн-системе Prizm">
  <p>
    <strong>Дизайн-система «PRIZM»</strong> представляет собой симбиоз дизайн-системы Spectr (ГПН БЛПС) и
    дизайн-системы ZIIoT design system 3.0, учитывает специфику промышленности, где представлена платформа,
    наш прошлый опыт и рынок, куда мы хотим попасть. Prizm распространяется по типу
    <a href="{{ storybookBaseUrl }}/about-prizm/license">лицензии MIT</a> и подходит для создания цифровых
    продуктов, включая бизнес-приложения поверх платформы ZIIoT. По сути, это единый визуальный язык и его
    техническое отражение в виде библиотеки компонентов в одном общем хранилище. Он не имеет отношения к
    back-end, а скорее представляет собой документ, объединяющий в себе информацию о решениях и требованиях,
    которые должны быть использованы при разработке и обновлении частей платформы и прикладных решений на
    ZIIoT. На него необходимо будет ссылаться при разработке технических заданий, руководств пользователя и
    прочих документов.
  </p>

  <h4>Для кого</h4>

  <ol class="disc">
    <li>Аналитиков</li>
    <li>Руководителей проектов</li>
    <li>Проектировщиков</li>
    <li>Владельцев продуктов</li>
    <li>Дизайнеров</li>
    <li>Тестировщиков</li>
    <li>Разработчиков</li>
    <li>Технических писателей</li>
  </ol>

  <h4>Из чего состоит дизайн-система</h4>

  <ol class="decimal">
    <li>Библиотека интерфейсных компонентов</li>
    <li>Библиотке компонентов в Figma</li>
    <li>Правила, рекомендации, гайдлайны, патерны, шаблоны, витрина компонентов.</li>
    <li>Философия к дизайну, разработке, поддержке.</li>
    <li>Команда Prizm.</li>
  </ol>
  <div class="doc-table">
    <div class="doc-cell">
      <h4>ЦЕЛЬ СОЗДАНИЯ</h4>
      <ol class="disc">
        <li>Снижение стоимости разработки</li>
        <li>Снижение стоимости дизайна</li>
        <li>Унификация множества интерфейсов</li>
      </ol>
    </div>
    <div class="doc-cell">
      <h4>ПРИЧИНА СОЗДАНИЯ</h4>
      <ol class="disc">
        <li>Разрозненность решений</li>
        <li>Отсутствие единого источника правды</li>
        <li>Поиски лучших решений в каждом проекте</li>
      </ol>
    </div>
    <div class="doc-cell">
      <h4>ЗАДАЧИ ДИЗАЙН-СИСТЕМЫ</h4>
      <ol class="disc">
        <li>Стандартизация решений</li>
        <li>Независимость проектов от состава команд</li>
        <li>Упрощение передачи продукта по стадиям «исследование – дизайн – разработка»</li>
        <li>Снижение затрат на поддержку, развитие и запуск продуктов</li>
        <li>Повышает узнаваемость бренда</li>
      </ol>
    </div>
    <div class="doc-cell">
      <h4>ПОЧЕМУ В ЭТОМ ЕСТЬ ЦЕННОСТЬ</h4>
      <ol class="disc">
        <li>Экономит время на дизайн / разработку</li>
        <li>Динамична и легко обновляемая</li>
        <li>Стандартизирует интерфейсы и минимизирует баги</li>
        <li>Служит готовым конструктором</li>
        <li>Легко масштабируется</li>
      </ol>
    </div>
    <div class="doc-cell">
      <h4>ПРИНЦИПЫ</h4>
      <ol class="disc">
        <li>Единый UX в продуктах</li>
        <li>Масштабируемость</li>
        <li>Дизайн на основе данных</li>
        <li>Локализация</li>
        <li>Сохранение интересов продукта</li>
        <li>Развитие и потенциал для эволюции</li>
        <li>Универсальность</li>
      </ol>
    </div>
    <div class="doc-cell">
      <h4>КАКИЕ ПРЕИМУЩЕСТВА ПОЛУЧАЕМ</h4>
      <ol class="disc">
        <li>Обучаемость пользователей</li>
        <li>Переиспользование графических элементов, паттернов</li>
        <li>Единообразие компонентов</li>
        <li>Удешевление тестирования</li>
        <li>Переиспользование кода / единые компоненты на технологическом уровне</li>
        <li>Структурированность</li>
      </ol>
    </div>
  </div>
</prizm-doc-page>
